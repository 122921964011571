<template>
  <div class="view-admin-guidance-download">
    <loading-screen :is-loading="isDeleting"></loading-screen>

    <!-- Is Loading -->
    <div v-if="isLoadingCombined" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <div class="page-header" v-if="!isLoadingCombined">
      <h1 class="page-title">Download: {{ download["$v"]["displayName"] }}</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type=""
          @click.prevent="goBack"
          >Back</a-button
        >

        <a-button
          v-if="canEdit"
          class="button-margin-left btn-rounded"
          icon="edit"
          size="large"
          type="primary"
          @click.prevent="edit"
          >Edit</a-button
        >

        <a-popconfirm
          v-if="canEdit"
          title="Are you sure?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="attemptDelete"
        >
          <a-button
            class="button-margin-left btn-rounded"
            icon="delete"
            size="large"
            type="danger"
            >Delete</a-button
          >
        </a-popconfirm>
      </div>
    </div>

    <!-- Tabs -->
    <div v-if="!isLoadingCombined">
      <!-- <a-tabs v-model="selectedTab">
        <a-tab-pane tab="Downloads" key="downloads">
            <download-downloads
             :download="download" :tenant-id="tenantId"
             :is-loading="isLoadingContentLinks"
             :content-links="contentLinks"
             @reload="loadContentLinks"
             ></download-downloads>
        </a-tab-pane>
      </a-tabs> -->

      <content-link-tabs
        :tenant-id="tenantId"
        :org-id="selectedOrganisation.id"
        :parent-id="download['$v'].id"
        parent-type="Download"
        :content-links="contentLinks"
        :is-loading="isLoadingContentLinks"
        :types="['articles']"
        :children-of="['articles']"
        :can-edit="canEdit"
        @refresh="loadContentLinks"
        :show-details-tab="true"
      >
        <a-button
          @click.prevent="downloadDocument"
          type="primary"
          icon="download"
          >Click to download document</a-button
        >
      </content-link-tabs>
    </div>
    <!-- / Tabs -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const _ = require("lodash");
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import policies from "../../../../api/guidance/policies";
import contentLinks from "../../../../api/guidance/content-links";
import ContentLinkTabs from "../../../../components/Admin/Guidance/ContentLinkTabs.vue";

export default {
  components: { LoadingScreen, ContentLinkTabs },

  data() {
    return {
      isLoadingDownload: true,
      download: null,

      isDeleting: false,

      isLoadingParentContentLinks: false,
      parentContentLinks: [],

      isLoadingChildrenContentLinks: false,
      childrenContentLinks: [],
    };
  },

  created() {
    // if (!this.download) {
    //   this.$message.info("Download not found");
    //   this.$router.push("/admin/guidance/downloads");
    // }
    this.loadDownload();
    this.loadContentLinks();
  },

  methods: {
    goBack() {
      if (this.$route.query.backTo) {
        this.$router.push(this.$route.query.backTo);
      } else {
        this.$router.push("/admin/guidance/downloads");
      }
    },

    edit() {
      this.$router.push(
        "/admin/guidance/downloads/" + this.downloadId + "/edit"
      );
    },

    downloadDocument() {
      window.open(
        this.download["$v"]["media"]["$v"]["source"]["$v"]["sourceUrl"]
      );
    },

    loadDownload() {
      let vm = this;
      vm.isLoadingDownload = true;
      policies
        .getPolicy(this.tenantId, this.downloadId)
        .then((r) => {
          vm.isLoadingDownload = false;
          vm.download = r.data;
        })
        .catch((e) => {
          console.log(e);
          this.$message.info("Download not found");
          this.$router.push("/admin/guidance/downloads");
        });
    },

    loadContentLinks() {
      this.loadChildrenContentLinks();
      this.loadParentContentLinks();
    },

    loadChildrenContentLinks() {
      let vm = this;
      vm.isLoadingChildrenContentLinks = true;
      contentLinks
        .getContentLinksForOrgByParentEntity(
          vm.tenantId,
          vm.selectedOrganisation.id,
          vm.downloadId
        )
        .then((r) => {
          vm.isLoadingChildrenContentLinks = false;
          vm.childrenContentLinks = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingChildrenContentLinks = false;
        });
    },

    loadParentContentLinks() {
      let vm = this;
      vm.isLoadingParentContentLinks = true;
      contentLinks
        .getContentLinksForOrgByChildEntity(
          vm.tenantId,
          vm.selectedOrganisation.id,
          vm.downloadId
        )
        .then((r) => {
          vm.isLoadingParentContentLinks = false;
          vm.parentContentLinks = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingParentContentLinks = false;
        });
    },

    attemptDelete() {
      let vm = this;
      vm.isDeleting = true;
      policies
        .deletePolicy(vm.tenantId, vm.downloadId)
        .then(() => {
          vm.isDeleting = false;
          vm.$message.success("Download deleted successfully");
          vm.$router.push("/admin/guidance/downloads");
        })
        .catch((e) => {
          vm.isDeleting = false;
          console.log(e);
          vm.$message.error("Error deleting download");
        });
    },
  },

  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),

    ...mapGetters("missionControl", {
      idsOfOrganisationsIAmAnAdminFor: "idsOfOrganisationsIAmAnAdminFor",
    }),

    isLoadingContentLinks() {
      return (
        this.isLoadingParentContentLinks || this.isLoadingChildrenContentLinks
      );
    },

    contentLinks() {
      return _.unionBy(
        this.parentContentLinks,
        this.childrenContentLinks,
        "$v.id"
      );
    },

    downloadId() {
      return this.$route.params.id;
    },

    isLoadingCombined() {
      return this.isLoading || this.isLoadingDownload;
    },

    canEdit() {
      if (!this.download) {
        return false;
      }
      return this.idsOfOrganisationsIAmAnAdminFor.includes(
        this.download["$v"]["ownerId"]
      );
    },
  },
};
</script>

<style lang="scss">
.view-admin-guidance-download {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .download-media-wrapper {
    min-height: 700px;
    background: #fff;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
  }
}
</style>